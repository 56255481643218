import { Component, OnInit } from '@angular/core';
import { ClickService } from '../services/click.service';
import { ApiService } from '../services/api.service';
import { CategoryService } from '../services/category.service';

@Component({
  selector: 'app-dashboard-analysis',
  templateUrl: './dashboard-analysis.component.html',
  styleUrls: ['./dashboard-analysis.component.scss']
})
export class DashboardAnalysisComponent implements OnInit {
  totalClicks: number | null = null;
  totalBusinesses: number | null = null;
  totalCategories: number | null = null;
  mostClickedBusiness: { name: string, clicks: number } | null = null;
  mostClickedCategory: { name: string, clicks: number } | null = null;
  clicksLastMonth: number | null = null;
  businessesLastMonth: number | null = null;
  clicksByBusiness: { name: string, clicks: number }[] = [];
  clicksByCategory: { name: string, clicks: number }[] = [];
  clicksByBusinessButtons: { name: string, totalClicks: number, buttons: { buttonName: string, count: number }[] }[] = [];

  constructor(
    private clickService: ClickService,
    private apiService: ApiService,
    private categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.loadOverviewData();
    this.loadBusinesses();
    this.loadCategories();
    this.loadClicksDetails();
    this.loadClicksByBusinessButtons();
  }

  // Carrega a visão geral de cliques
  async loadOverviewData() {
    try {
      const overview = await this.clickService.getClicksOverview();
      this.totalClicks = overview.totalClicks;

      // Negócio mais clicado
      const mostClickedBusiness = overview.clicksByBusiness.reduce((max, business) =>
        business.count > max.count ? business : max
      , { _id: '', count: 0 });

      this.mostClickedBusiness = {
        name: await this.getBusinessNameById(mostClickedBusiness._id),
        clicks: mostClickedBusiness.count
      };

      // Categoria mais clicada
      const mostClickedCategory = overview.clicksByCategory.reduce((max, category) =>
        category.count > max.count ? category : max
      , { _id: '', count: 0 });

      this.mostClickedCategory = {
        name: await this.getCategoryNameById(mostClickedCategory._id),
        clicks: mostClickedCategory.count
      };
    } catch (error) {
      console.error('Erro ao carregar a visão geral de cliques:', error);
    }
  }

  // Carrega os negócios e total
  async loadBusinesses() {
    try {
      const businesses = await this.apiService.getBusinesses();
      this.totalBusinesses = businesses.length;

      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      this.businessesLastMonth = businesses.filter((business: { timestamp: string | number | Date; }) =>
        new Date(business.timestamp) >= oneMonthAgo
      ).length;
    } catch (error) {
      console.error('Erro ao carregar os negócios:', error);
    }
  }

  // Carrega as categorias e total
  async loadCategories() {
    try {
      const categories = await this.categoryService.getCategories();
      this.totalCategories = categories.length;
    } catch (error) {
      console.error('Erro ao carregar as categorias:', error);
    }
  }

  // Carrega detalhes de cliques por negócios e categorias
  async loadClicksDetails() {
    try {
      const overview = await this.clickService.getClicksOverview();

      this.clicksByBusiness = await Promise.all(
        overview.clicksByBusiness.map(async (business) => ({
          name: await this.getBusinessNameById(business._id),
          clicks: business.count
        }))
      );

      this.clicksByCategory = await Promise.all(
        overview.clicksByCategory.map(async (category) => ({
          name: await this.getCategoryNameById(category._id),
          clicks: category.count
        }))
      );
    } catch (error) {
      console.error('Erro ao carregar detalhes de cliques:', error);
    }
  }

  // Carrega cliques por negócios e botões
  async loadClicksByBusinessButtons() {
    try {
      const buttonClicks = await this.clickService.getButtonClickSummary();
      this.clicksByBusinessButtons = await Promise.all(
        buttonClicks.map(async (business) => ({
          name: await this.getBusinessNameById(business.businessId),
          totalClicks: business.totalClicks,
          buttons: business.buttons
        }))
      );
    } catch (error) {
      console.error('Erro ao carregar cliques por negócios e botões:', error);
    }
  }

  // Obtém o nome do negócio pelo ID
  async getBusinessNameById(id: string): Promise<string> {
    const business = await this.apiService.getBusinesses();
    return business.find((b: { id: string; }) => b.id === id)?.name || 'Desconhecido';
  }

  // Obtém o nome da categoria pelo ID
  async getCategoryNameById(id: string): Promise<string> {
    const categories = await this.categoryService.getCategories();
    return categories.find((c: { id: string; }) => c.id === id)?.name || 'Desconhecida';
  }
}