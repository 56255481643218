<div class="container">

  <!-- Botão para voltar -->
  <div class="row mb-4">
    <div class="col-12 text-start">
      <button class="btn btn-primary" [routerLink]="['/dashboard']">
        Voltar ao Dashboard
      </button>
    </div>
  </div>

  <div class="row">
    <!-- Total de Cliques -->
    <div class="col-md-6">
      <div class="card text-center shadow">
        <div class="card-header">Total de Cliques</div>
        <div class="card-body">
          <h1 class="card-title text-primary">
            {{ totalClicks !== null ? totalClicks : 'Carregando...' }}
          </h1>
          <p class="card-text">Número total de cliques registrados.</p>
        </div>
      </div>
    </div>

    <!-- Total de Negócios -->
    <div class="col-md-6">
      <div class="card text-center shadow">
        <div class="card-header">Total de Negócios</div>
        <div class="card-body">
          <h1 class="card-title text-success">
            {{ totalBusinesses !== null ? totalBusinesses : 'Carregando...' }}
          </h1>
          <p class="card-text">Número total de negócios cadastrados.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <!-- Negócio Mais Clicado -->
    <div class="col-md-6">
      <div class="card text-center shadow">
        <div class="card-header">Negócio Mais Clicado</div>
        <div class="card-body">
          <h1 class="card-title text-warning">
            {{ mostClickedBusiness?.name || 'Carregando...' }}
          </h1>
          <p class="card-text">Cliques: {{ mostClickedBusiness?.clicks || '...' }}</p>
        </div>
      </div>
    </div>

    <!-- Categoria Mais Clicada -->
    <div class="col-md-6">
      <div class="card text-center shadow">
        <div class="card-header">Categoria Mais Clicada</div>
        <div class="card-body">
          <h1 class="card-title text-info">
            {{ mostClickedCategory?.name || 'Carregando...' }}
          </h1>
          <p class="card-text">Cliques: {{ mostClickedCategory?.clicks || '...' }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <!-- Lista de Cliques por Negócios -->
    <div class="col-md-6">
      <div class="card shadow">
        <div class="card-header">Cliques por Negócios</div>
        <div class="card-body" style="max-height: 300px; overflow-y: auto;">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let business of clicksByBusiness">
              {{ business.name }}
              <span class="badge bg-primary rounded-pill">{{ business.clicks }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Lista de Cliques por Categorias -->
    <div class="col-md-6">
      <div class="card shadow">
        <div class="card-header">Cliques por Categorias</div>
        <div class="card-body" style="max-height: 300px; overflow-y: auto;">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let category of clicksByCategory">
              {{ category.name }}
              <span class="badge bg-success rounded-pill">{{ category.clicks }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <!-- Lista de Cliques por Negócios e Botões -->
    <div class="col-md-12">
      <div class="card shadow">
        <div class="card-header">Cliques por Negócios e Botões</div>
        <div class="card-body" style="max-height: 300px; overflow-y: auto;">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let business of clicksByBusinessButtons">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <strong>{{ business.name }}</strong>
                  <ul>
                    <li *ngFor="let button of business.buttons">
                      Botão: {{ button.buttonName }} - Cliques: {{ button.count }}
                    </li>
                  </ul>
                </div>
                <span class="badge bg-primary rounded-pill">{{ business.totalClicks }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>