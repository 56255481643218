import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PolicyComponent } from './policy/policy.component';  // Importando o componente Policy
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { AuthGuard } from '../app/auth/auth.guard';
import { CategoriesComponent } from './categories/categories.component';
import { DashboardAnalysisComponent } from './dashboard-analysis/dashboard-analysis.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard]},
  { path: 'categories', component: CategoriesComponent , canActivate: [AuthGuard]},
  { path: 'analysis', component: DashboardAnalysisComponent,canActivate: [AuthGuard]},
  { path: 'policy', component: PolicyComponent },
  { path: 'terms', component: TermsConditionsComponent }    // Definindo a rota para Policy
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
