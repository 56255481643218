import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class ClickService {
  private BASE_URL = 'https://brazuca-indica-api-daabaf6ea0bb.herokuapp.com/clicks'; // Base URL para cliques gerais
  private BUTTON_CLICKS_URL = 'https://brazuca-indica-api-daabaf6ea0bb.herokuapp.com/button-clicks'; // Base URL para cliques em botões

  constructor() {}

  // Visão geral de cliques gerais
  async getClicksOverview(): Promise<{ totalClicks: number, clicksByCategory: any[], clicksByBusiness: any[] }> {
    try {
      const response = await axios.get(`${this.BASE_URL}/overview`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar visão geral de cliques:', error);
      throw error;
    }
  }

  // Cliques por negócio
  async getClicksByBusiness(businessId: string): Promise<{ businessId: string, clickCount: number }> {
    try {
      const response = await axios.get(`${this.BASE_URL}/business/${businessId}`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar cliques para o negócio ${businessId}:`, error);
      throw error;
    }
  }

  // Cliques por categoria
  async getClicksByCategory(categoryId: string): Promise<{ categoryId: string, clickCount: number }> {
    try {
      const response = await axios.get(`${this.BASE_URL}/category/${categoryId}`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar cliques para a categoria ${categoryId}:`, error);
      throw error;
    }
  }

  // Registrar clique geral
  async registerClick(businessId: string, categoryId: string, action: string = ''): Promise<any> {
    try {
      const payload = { businessId, categoryId, action };
      const response = await axios.post(`${this.BASE_URL}/`, payload);
      return response.data;
    } catch (error) {
      console.error('Erro ao registrar clique:', error);
      throw error;
    }
  }

  // Cliques por ações em um negócio
  async getActionClicksByBusiness(businessId: string): Promise<{ action: string, count: number }[]> {
    try {
      const response = await axios.get(`${this.BASE_URL}/business/${businessId}/actions`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar cliques por ações no negócio ${businessId}:`, error);
      throw error;
    }
  }

  // Resumo de cliques em botões
  async getButtonClickSummary(): Promise<
    { businessId: string; totalClicks: number; buttons: { buttonName: string; count: number }[] }[]
  > {
    try {
      const response = await axios.get(`${this.BUTTON_CLICKS_URL}/buttons/summary`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter resumo de cliques em botões:', error);
      throw error;
    }
  }
}